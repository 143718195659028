import React from 'react';
import { ReactComponent as InMaintenance } from '../assets/rafiki.svg';
import LogoApp from '../assets/sebrae-one-blue.png';

export default function AppInMaintenance() {
  return (
    <div
      style={{
        height: '100vh',
        maxWidth: '100vw',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={LogoApp} alt="Logo app" height="150rem" />

      <h2
        style={{
          color: '#000',
          fontSize: '4rem',
          fontWeight: 'bold',
          marginBottom: '0.4rem',
          marginTop: '4rem',
          textAlign: 'center',
        }}
      >
        Estamos em manutenção
      </h2>
      <h5
        style={{
          color: '#0e72b8',
          fontSize: '1rem',
          textAlign: 'center',
          marginTop: 0,
          marginBottom: '0.4rem',
        }}
      >
        Estamos em manutenção para você ter uma experiência ainda melhor
        conosco.
      </h5>
      <h5
        style={{
          color: '#0e72b8',
          fontSize: '1rem',
          marginTop: 0,
          marginBottom: '4rem',
        }}
      >
        Volte mais tarde.
      </h5>

      <InMaintenance />
    </div>
  );
}
