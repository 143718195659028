import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './hooks/useAuth';
import { SnackbarProvider } from './hooks/useSnackbar';
import { ModalLoading } from './hooks/useModalLoading';
import { QueryProvider } from './hooks/useQuery';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as Sentry from '@sentry/react';
import App from './App';

if (process.env.REACT_APP_NAME == 'prod') {
  Sentry.init({
    dsn: 'https://5775034a6e524b6a82af4880d678e4a2@o4504180579172352.ingest.sentry.io/4505070537867264',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

const theme = createTheme({
  palette: {
    primary: { main: '#3a34d2' },
  },
});

root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <QueryProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <ModalLoading>
                <App />
              </ModalLoading>
            </SnackbarProvider>
          </ThemeProvider>
        </QueryProvider>
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>,
);
