import { Routes, Route } from 'react-router-dom';
import { LoginPage } from './pages/Login';
import { ContratosPage } from './pages/Contratos';
import { ProtectedLayout } from './components/ProtectedLayout';
import { HomeLayout } from './components/HomeLayout';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { NotasPage } from './pages/Notas';
import './styles.css';
import { RecuperarSenhaPage } from './pages/RecuperarSenha';
import { AlterarSenhaPage } from './pages/AlterarSenha';
import { NovaNotaPage } from './pages/NovaNota';
import Extratos from './pages/Extratos';
import MeusDados from './pages/MeusDados';
import DadosBancarios from './pages/DadosBancarios';
import JustificarAlteracoesNota from './pages/JustificarAlteracoesNota';
import LayoutXml from './pages/LayoutXml';
import { ProtectedRoute } from './components/ProtectedRoute';
import CancelarNotaFiscal from './pages/CancelarNotaFiscal';
import { PagamentoDiretoPage } from './pages/PagamentoDireto';
import ListaPagamentosPage from './pages/ListaPagamentos';
import { ProtectedRouteSebrae } from './components/ProtectedRouteSebrae';
import { LogsPage } from './pages/Logs';
import PainelNotas from './pages/PainelNotas';
import { CadastroFornecedorPage } from './pages/CadastroFornecedor';
import ListaCadastroFornededoresPage from './pages/ListaCadastroFornecedores';
import { AvisosPage } from './pages/Avisos';
import { NovoAvisoPage } from './pages/NovoAviso';
import { CertidoesPage } from './pages/Certidoes';
import { CadastroPage } from './pages/Cadastro';
import { InformeRendimentosPage } from './pages/InformeRendimentos';
import { ExportarNotasPage } from './pages/ExportarNotas';
import CancelarPagamentoDireto from './pages/CancelarPagamentoDireto';
import NotaFiscalVisualizar from './pages/NotaFiscalVisualizar';
import { FaqsPage } from './pages/Faqs';
import { NovoFaqPage } from './pages/NovoFaq';
import { ListarFaqPage } from './pages/ListarFaq';
import { CalendarioPage } from './pages/Calendario';
import { NovoCalendarioPage } from './pages/NovoCalendario';
import FailedJobs from './pages/FailedJobs';
import { ExportarInformePage } from './pages/ExportarInforme';
import AppInMaintenance from './pages/AppInMaintenance';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0e72b8',
      light: '#40C4FF',
    },
    success: {
      main: '#00E126',
    },
    warning: {
      main: '#FFA500',
    },
    error: {
      main: '#DC143C',
    },
    info: {
      main: '#9932CC',
    },
    secondary: {
      main: '#fff',
    },
  },
});
export default function App() {
  const appInMaintenance = process.env.REACT_APP_EM_MANUTENCAO === 'true';

  if (appInMaintenance) return <AppInMaintenance />;

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<HomeLayout />}>
          {/* <Route path="/" element={<HomePage />} /> */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/recuperar-senha" element={<RecuperarSenhaPage />} />
          <Route path="/cadastrar" element={<CadastroPage />} />
          {/* <Route path="/cadastro-fornecedor" element={<CadastroFornecedorPage />} /> */}
        </Route>
        <Route path="/alterar-senha" element={<AlterarSenhaPage />} />
        <Route path="/dashboard" element={<ProtectedLayout />}>
          <Route path="contratos" element={<ContratosPage />} />
          <Route path="meus-dados" element={<MeusDados />} />
          <Route
            path="informe-rendimentos"
            element={<InformeRendimentosPage />}
          />
          <Route
            path="informe-rendimentos-relatorio"
            element={<ExportarInformePage />}
          />
          <Route path="meus-dados-bancarios" element={<DadosBancarios />} />
          <Route path="faq" element={<ListarFaqPage />} />
          <Route
            path="nota/visualizar/:nota_fiscal_id"
            element={<NotaFiscalVisualizar />}
          />
          <Route path="notas/:contratoId" element={<NotasPage />} />
          <Route path="nota/:contratoId" element={<NovaNotaPage />} />
          <Route
            path="cancelar-nota/:notaId"
            element={<CancelarNotaFiscal />}
          />
          <Route
            path="cancelar-pagamento-direto/:pagamentoId"
            element={<CancelarPagamentoDireto />}
          />
          <Route
            path="justificar-nota/:notaId"
            element={<JustificarAlteracoesNota />}
          />
          <Route path="extrato" element={<Extratos />} />
          <Route
            path="layout-xml"
            element={
              <ProtectedRoute>
                <LayoutXml />
              </ProtectedRoute>
            }
          />
          <Route
            path="pagamento-direto"
            element={
              <ProtectedRouteSebrae>
                <ListaPagamentosPage />
              </ProtectedRouteSebrae>
            }
          />
          <Route
            path="criar-pagamento-direto"
            element={
              <ProtectedRouteSebrae>
                <PagamentoDiretoPage />
              </ProtectedRouteSebrae>
            }
          />
          <Route
            path="logs"
            element={
              <ProtectedRoute>
                <LogsPage />
              </ProtectedRoute>
            }
          />
          <Route path="painel-notas" element={<PainelNotas />} />
          <Route
            path="cadastro-fornecedor"
            element={<CadastroFornecedorPage />}
          />
          <Route
            path="listar-cadastros"
            element={<ListaCadastroFornededoresPage />}
          />
          <Route path="listar-avisos" element={<AvisosPage />} />
          <Route path="criar-aviso" element={<NovoAvisoPage />} />
          <Route
            path="exportar-notas"
            element={
              <ProtectedRouteSebrae>
                <ExportarNotasPage />
              </ProtectedRouteSebrae>
            }
          />
          <Route path="listar-faq" element={<FaqsPage />} />
          <Route path="criar-faq" element={<NovoFaqPage />} />
          <Route path="listar-calendario" element={<CalendarioPage />} />
          <Route path="criar-calendario" element={<NovoCalendarioPage />} />
          <Route
            path="failed-jobs"
            element={
              <ProtectedRoute>
                <FailedJobs />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
