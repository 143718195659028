import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import axios from 'axios';

const QueryContext = createContext();

export const QueryProvider = ({ children }) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const url = process.env.REACT_APP_API_URL;
  const urlBff = process.env.REACT_APP_URL_BFF;

  axios.interceptors.response.use(
    (response) => {
      // block to handle success case
      if (
        response &&
        response.data &&
        response.data.error &&
        response.data.error == 'Accesso negado.'
      ) {
        logout();
      }

      return response;
    },
    function (error) {
      // block to handle error case
      return Promise.reject(error);
    },
  );

  const listarContratos = async (cnpj, params = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/contratos?cnpj=${cnpj}${params}`, config);
  };

  const detalheContrato = (contrato, filtro = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/contrato?contrato=${contrato}${filtro}`, config);
  };

  const detalhePedido = (pedido) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/pedido?pedido=${pedido}`, config);
  };

  const listarNotas = async (data) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/notas`, data, config);
  };

  const listarLogs = async (data) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/logs`, data, config);
  };

  const enviarNota = async (data) => {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/enviar-nota`, data, config);
  };

  const enviarNotaManual = async (data) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    };
    return axios.post(`${url}/criar-nota-manual`, data, config);
  };

  const criarNotaFiscal = async (payload) => {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
      },
    };
    return axios.post(`${url}/nota-fiscal`, payload, config);
  };

  const atualizarNota = async (id, payload) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    };
    return axios.put(`${url}/nota-fiscal/${id}`, payload, config);
  };

  const parsearXml = async (payload) => {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
      },
    };
    return axios.post(`${url}/parser-xml`, payload, config);
  };

  const parsearPdf = async (payload) => {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
      },
    };
    return axios.post(`${url}/parser-pdf`, payload, config);
  };

  const enviarOrdemServico = async (data) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/criar-ordem-servico`, data, config);
  };

  const listarFornecedores = async (term = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/fornecedores?term=${term}`, config);
  };

  const listarMunicipios = (estado_id = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/municipios?estado_id=${estado_id}`, config);
  };

  const listarArquivosNotaFiscal = (nota_fiscal_id, tipo = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(
      `${url}/nota-fiscal/${nota_fiscal_id}/arquivos?tipo=${tipo}`,
      config,
    );
  };

  const obterHistoricoStatusNotaFiscal = (nota_fiscal_id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/nota-fiscal/${nota_fiscal_id}/statuses`, config);
  };

  const obterFornecedorPorCnpj = (cnpj) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/fornecedores/${cnpj}`, config);
  };

  const obterNotaFiscalPorId = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/nota-fiscal/${id}`, config);
  };

  const deletarArquivoDaNotaFiscal = (file_id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/arquivos/${file_id}`, config);
  };
  const obterOrdemsDeServicoPeloIdDaNota = (nota_fiscal_id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    return axios.get(
      `${url}/nota-fiscal/${nota_fiscal_id}/ordens-de-servico`,
      config,
    );
  };

  const atualizarArquivoDaNotaFiscal = (file_id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.put(`${url}/arquivos/${file_id}`, payload, config);
  };

  const enviarArquivosNotaFiscal = (nota_fiscal_id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(
      `${url}/nota-fiscal/${nota_fiscal_id}/arquivos`,
      payload,
      config,
    );
  };

  const enviarArquivosTxt = (data) => {
    return fetch(
      `${url}/enviar-txt?nota_id=${data.nota_id}&cnpj=${data.cnpj}&numero_nf=${data.numero_nf}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  };

  const enviarFluig = (
    nfs_id,
    isContract,
    radioValue,
    ateste = null,
    isUsuarioSebrae,
    isPrioritario,
  ) => {
    let endpoint = '';
    if (ateste !== null) {
      endpoint = `${url}/enviar-fluig?nota_id=${nfs_id}&is_contract=${
        isContract ? 1 : 0
      }&radio_value=${radioValue}&ateste_login=${ateste.login}&ateste_nome=${
        ateste.nome
      }&ateste_matricula=${
        ateste.matricula
      }&isUsuarioSebrae=${isUsuarioSebrae}&isPrioritario=${isPrioritario}`;
    } else {
      endpoint = `${url}/enviar-fluig?nota_id=${nfs_id}&is_contract=${
        isContract ? 1 : 0
      }&radio_value=${radioValue}&isUsuarioSebrae=${isUsuarioSebrae}&isPrioritario=${isPrioritario}`;
    }
    return fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const listarEstados = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/estados`, config);
  };

  const buscarExtratos = (params) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/extratos${params}`, config);
  };

  const cancelarNota = (id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${url}/cancelar-nota/${id}`, payload, config);
  };

  const deletarNota = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/deletar-nota/${id}`, config);
  };

  const getFieldsTree = (data) => {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/tree`, data, config);
  };

  const getFieldsNota = () => {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/nota-fiscal-campos`, config);
  };

  const saveLayout = (data) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/criar-layout-xml`, data, config);
  };

  const getLayout = ({ municipioId, estado, naturezaoperacao }) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(
      `${url}/buscar-layout?municipio_id=${municipioId}&estado=${estado}&naturezaoperacao=${naturezaoperacao}`,
      config,
    );
  };

  const getCombos = (name) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/combos-pagamento?name=${name}`, config);
  };

  const getSaldo = (param) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/dados-saldo?param=${param}`, config);
  };

  const enviarPagamentoDireto = (data) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/criar-pagamento-direto`, data, config);
  };

  const deletarArquivoPagamentoDireto = (file_id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/pagamento-direto-arquivos/${file_id}`, config);
  };

  const atualizarArquivoPagamentoDireto = (file_id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.put(
      `${url}/pagamento-direto-arquivos/${file_id}`,
      payload,
      config,
    );
  };

  const enviarArquivosPagamentoDireto = (pagamento_direto_id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(
      `${url}/pagamento-direto/${pagamento_direto_id}/arquivos`,
      payload,
      config,
    );
  };

  const listarArquivosPagamentoDireto = (pagamento_direto_id, tipo = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(
      `${url}/pagamento-direto/${pagamento_direto_id}/arquivos?tipo=${tipo}`,
      config,
    );
  };

  const listarPagamentoDireto = (param, pagina) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/pagamento-direto${param}&page=${pagina}`, config);
  };

  const enviarPagamentoDiretoFluig = (
    pagamentoId,
    login,
    bolsista,
    user_id,
  ) => {
    return fetch(
      `${url}/enviar-pagamento-fluig?pagamento_direto_id=${pagamentoId}&login=${login}&bolsista=${bolsista}&user_id=${user_id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  };

  const enviarPagamentoDiretoDataSul = (pagamentoId) => {
    return fetch(
      `${url}/enviar-pagamento-datasul?pagamento_direto_id=${pagamentoId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  };

  const getPagamentoDireto = (pagamentoId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/pagamento-direto/${pagamentoId}`, config);
  };
  const listarNotificacoesNaoLidas = async (term) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/notificacoes-nao-lidas`, config);
  };

  const marcarComoLida = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    };

    return axios.post(`${url}/mark-as-read`, { id: id }, config);
  };

  const listarUsersFluig = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/users-fluig`, config);
  };

  const primeiraOrdem = async (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/nota-fiscal/${id}/primeira-ordem`, config);
  };

  const cadastroStep1 = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${url}/cadastro-step-1`, payload, config);
  };

  const cadastroStep2 = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${url}/cadastro-step-2`, payload, config);
  };

  const cadastroStep3 = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${url}/cadastro-step-3`, payload, config);
  };

  const cadastroStep4 = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${url}/cadastro-step-4`, payload, config);
  };

  const cadastroStep5 = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${url}/cadastro-step-5`, payload, config);
  };

  const listarCadastrosFornecedores = (param) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/listar-cadastros${param}`, config);
  };

  const showCadastroFornecedor = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/cadastro-fornecedor/${id}`, config);
  };

  const cancelCadastroFornecedor = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(
      `${url}/cadastro-fornecedor/${id}/cancelar`,
      null,
      config,
    );
  };

  const deletarColaborador = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/colaborador/${id}`, config);
  };

  const deletarDadosBancarios = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/dados-bancarios/${id}`, config);
  };

  const getCustomer = (document) => {
    return fetch(`${url}/customer/${document}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getCompany = (document) => {
    return fetch(`${url}/company/${document}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const atualizarAviso = (id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.put(`${url}/atualizar-aviso/${id}`, payload, config);
  };

  const deletarAviso = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/deletar-aviso/${id}`, config);
  };

  const showAviso = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/aviso/${id}`, config);
  };

  const listarAvisos = () => {
    return axios.get(`${url}/listar-avisos`);
  };

  const criarAviso = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/criar-aviso`, payload, config);
  };

  const deletarCadastro = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/fornecedor/${id}`, config);
  };

  const listarBancos = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/bancos`, config);
  };

  const getColaborador = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/colaborador/${id}`, config);
  };

  const listarPaises = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/paises`, config);
  };

  const deletarArquivoFornecedor = (file_id, dados_edicao = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(
      `${url}/fornecedor-arquivos/${file_id}?dados_edicao=${dados_edicao}`,
      config,
    );
  };

  const atualizarArquivoFornecedor = (file_id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.put(`${url}/fornecedor-arquivos/${file_id}`, payload, config);
  };

  const enviarArquivosFornecedor = (fornecedor_id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(
      `${url}/fornecedor/${fornecedor_id}/arquivos`,
      payload,
      config,
    );
  };

  const listarArquivosFornecedor = (fornecedor_id, tipo = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(
      `${url}/fornecedor/${fornecedor_id}/arquivos?tipo=${tipo}`,
      config,
    );
  };

  const enviarInformeRendimentos = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${url}/informe-rendimentos`, payload, config);
  };

  const listarInformeRendimentos = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/informe-rendimentos-listar`, config);
  };

  const getCep = (cep) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return axios.get(`${url}/consulta-cep?cep=${cep}`, config);
  };

  const getExcelData = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    };

    return axios.post(`${url}/exportar-notas`, payload, config);
  };

  const listarComboFornecedor = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/lista-combo-fornecedores`, config);
  };

  const downloadInforme = (param) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/download-rendimentos${param}`, config);
  };

  const obterHistoricoStatusPagamentoDireto = (pagamento_id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(
      `${url}/pagamento-direto/${pagamento_id}/statuses`,
      config,
    );
  };

  const deletarPagamentoDireto = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/pagamento-direto/${id}`, config);
  };

  const cancelarPagamentoDireto = (id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(
      `${url}/cancelar-pagamento-direto/${id}`,
      payload,
      config,
    );
  };

  const enviarCadastroFluig = (fornecedorId, login) => {
    return fetch(
      `${url}/enviar-cadastro-fluig?fornecedor_id=${fornecedorId}&responsavel=${login}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  };

  const enviarJustificativaFornecedor = (fornecedor_id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.put(
      `${url}/fornecedor/${fornecedor_id}/justificativa`,
      payload,
      config,
    );
  };

  const buscaCC = (param) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/buscar-centro-custo${param}`, config);
  };

  const getStatusFluig = (fluig) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/pagamento-direto-status/${fluig}`, config);
  };

  const listarFaqs = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/listar-faq`, config);
  };

  const criarFaqs = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/criar-faq`, payload, config);
  };

  const atualizarFaq = (id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.put(`${url}/atualizar-faq/${id}`, payload, config);
  };

  const deletarFaq = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/deletar-faq/${id}`, config);
  };

  const showFaq = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/faq/${id}`, config);
  };

  const listarCalendario = (param) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/listar-calendario${param}`, config);
  };

  const validarPermissaoEnvioNota = (payload = null) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(
      `${url}/nota-fiscal/validar-data-emissao`,
      payload,
      config,
    );
  };

  const listarCalendarioPublico = () => {
    return axios.get(`${url}/listar-calendario-publico?recurso_aviso=true`);
  };

  const criarCalendario = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/criar-calendario`, payload, config);
  };

  const atualizarCalendario = (id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.put(`${url}/atualizar-calendario/${id}`, payload, config);
  };

  const deletarCalendario = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.delete(`${url}/deletar-calendario/${id}`, config);
  };

  const showCalendario = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/calendario/${id}`, config);
  };

  const getComboTipoServico = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/lista-combo-tipo-servico`, config);
  };

  const getComboNaturezaRendimento = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/lista-combo-natureza-rendimento`, config);
  };

  const atualizarFormaRecebimento = (id, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(
      `${url}/atualizar-forma-recebimento/${id}`,
      payload,
      config,
    );
  };

  const criarFornecedorDatasul = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/criar-fornecedor-datasul`, payload, config);
  };

  const verificarFornecedor = async (documento) => {
    let config = {};

    return axios.get(
      `${url}/verificar-fornecedor?documento=${documento}`,
      config,
    );
  };

  const fetchFailedJobs = (search = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.get(`${url}/failed-jobs?search=${search}`, config);
  };

  const retryFailedJobs = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    return axios.post(`${url}/failed-jobs`, payload, config);
  };

  const fetchRegionais = () => {
    return axios.get(`${url}/regionais`, {});
  };

  const atualizarRegional = async (payload) => {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return axios.post(`${url}/atualizar-regional/`, payload, config);
  };

  const listarRelatorioInforme = (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    };

    return axios.post(`${url}/informe-rendimentos-relatorio`, payload, config);
  };

  const value = useMemo(() => ({
    listarContratos,
    detalheContrato,
    listarNotas,
    listarFornecedores,
    detalhePedido,
    enviarNota,
    listarMunicipios,
    enviarNotaManual,
    listarArquivosNotaFiscal,
    enviarArquivosNotaFiscal,
    deletarArquivoDaNotaFiscal,
    atualizarArquivoDaNotaFiscal,
    enviarOrdemServico,
    enviarArquivosTxt,
    enviarFluig,
    obterFornecedorPorCnpj,
    obterHistoricoStatusNotaFiscal,
    obterNotaFiscalPorId,
    atualizarNota,
    obterOrdemsDeServicoPeloIdDaNota,
    listarEstados,
    buscarExtratos,
    cancelarNota,
    parsearXml,
    criarNotaFiscal,
    getFieldsTree,
    getFieldsNota,
    saveLayout,
    getLayout,
    deletarNota,
    getCombos,
    getSaldo,
    enviarPagamentoDireto,
    listarArquivosPagamentoDireto,
    atualizarArquivoPagamentoDireto,
    enviarArquivosPagamentoDireto,
    deletarArquivoPagamentoDireto,
    listarPagamentoDireto,
    enviarPagamentoDiretoFluig,
    listarLogs,
    getPagamentoDireto,
    listarNotificacoesNaoLidas,
    marcarComoLida,
    enviarPagamentoDiretoDataSul,
    listarUsersFluig,
    primeiraOrdem,
    cadastroStep1,
    cadastroStep2,
    cadastroStep3,
    cadastroStep4,
    cadastroStep5,
    listarCadastrosFornecedores,
    showCadastroFornecedor,
    deletarColaborador,
    getCustomer,
    deletarDadosBancarios,
    getCompany,
    criarAviso,
    listarAvisos,
    deletarAviso,
    atualizarAviso,
    showAviso,
    deletarCadastro,
    listarBancos,
    getColaborador,
    listarPaises,
    enviarArquivosFornecedor,
    listarArquivosFornecedor,
    atualizarArquivoFornecedor,
    deletarArquivoFornecedor,
    listarInformeRendimentos,
    enviarInformeRendimentos,
    getCep,
    getExcelData,
    listarComboFornecedor,
    downloadInforme,
    obterHistoricoStatusPagamentoDireto,
    deletarPagamentoDireto,
    cancelarPagamentoDireto,
    enviarCadastroFluig,
    buscaCC,
    getStatusFluig,
    listarFaqs,
    criarFaqs,
    showFaq,
    atualizarFaq,
    deletarFaq,
    listarCalendario,
    criarCalendario,
    showCalendario,
    atualizarCalendario,
    deletarCalendario,
    listarCalendarioPublico,
    getComboTipoServico,
    getComboNaturezaRendimento,
    validarPermissaoEnvioNota,
    atualizarFormaRecebimento,
    verificarFornecedor,
    criarFornecedorDatasul,
    parsearPdf,
    fetchFailedJobs,
    retryFailedJobs,
    fetchRegionais,
    atualizarRegional,
    listarRelatorioInforme,
    enviarJustificativaFornecedor,
    cancelCadastroFornecedor,
  }));

  return (
    <QueryContext.Provider value={value}>{children}</QueryContext.Provider>
  );
};

export const useQuery = () => {
  return useContext(QueryContext);
};
