import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StringToReact from 'string-to-react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme, custom }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: '90%',
        border: 'none',
        overflow:  'hidden'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
       width: 800
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogsOS({ title, content, btnTxt, type, onClick, visible, handleClose, isDetalhe = false, onClickDetalhe }) {
    const theme = useTheme();

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={visible}
                custom={isDetalhe}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} style={{ textAlign: 'center' }}>
                    {type === 'success' ? (
                        <CheckCircleIcon style={{ fontSize: 60 }} color='success' />
                    ) : (
                        <CancelIcon style={{ fontSize: 60 }} color='error' />
                    )}
                </BootstrapDialogTitle>
                <DialogContent style={{ overflowY: 'auto' }} >
                    {!isDetalhe ? (
                        <Grid container spacing={2} margin={2}>
                            {content && Array.isArray(content) && content.map(result => {
                                return (<Grid item sm={12}>
                                    <p style={{ cursor: 'pointer' }} onClick={() => { onClickDetalhe(result.ordem) }}>{result.message}<span style={{color: '#0e72b8', textDecoration: 'underline', marginLeft: 5}}>Ver detalhes</span></p>
                                </Grid>);
                            })}
                        </Grid>
                    ) : (
                        <Grid container spacing={2} margin={2}>
                            <Grid container sm={12} item>
                                <div style={{ width: '85%', border: '1px solid rgb(0, 102, 158)', borderTop: `8px solid ${theme.palette.primary.light}`, borderRadius: 10, padding: 20, justifyContent: 'space-between', display: 'flex' }}>
                                    <div style={{ width: '30%' }}>
                                        <span style={{ color: 'rgb(0, 102, 158)', marginRight: 20 }}><b>Contrato</b></span>
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        <span style={{ color: 'rgb(0, 102, 158)', marginRight: 20 }}><b>Nº documento</b></span>
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        <span style={{ color: 'rgb(0, 102, 158)', marginRight: 20 }}><b>Valor</b></span>
                                    </div>
                                </div>
                            </Grid>
                            {content && content.map(result => {
                                return (<Grid container sm={12} item>
                                    <div style={{ width: '85%', border: '1px solid rgb(0, 102, 158)', borderRadius: 10, padding: 20, justifyContent: 'space-between', display: 'flex' }}>
                                        <div style={{ width: '30%' }}>
                                            <span style={{ color: 'rgb(0, 102, 158)', marginRight: 20 }}>{result.nota_fiscal && result.nota_fiscal.codigo_contrato}</span>
                                        </div>
                                        <div style={{ width: '30%' }}>
                                            <span>{result.nota_fiscal && result.nota_fiscal.numero_nf}</span>
                                        </div>
                                        <div style={{ width: '30%' }}>
                                            <span>R${result && result.outro_valor} </span>
                                        </div>
                                    </div>
                                </Grid>)
                            })}
                        </Grid>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleClose();
                        if (type === 'success') {
                            onClick();
                        }
                    }}>
                        {btnTxt}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div >
    );
}
