import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StringToReact from "string-to-react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
const BootstrapDialog = styled(Dialog)(({ theme, custom }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: custom ? "auto" : 400,
    border: "none",
    overflow: custom ? "hidden" : "auto",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs({
  title,
  content,
  btnTxt,
  type,
  onClick,
  visible,
  handleClose,
  isHtml = false,
  ordens = [],
  onClickDetalhe,
}) {
  const renderComponent = (conteudo) => {
    let transform = function (tagName) {
      if (tagName === "Box ") return Box;
      return null;
    };
    return StringToReact(conteudo, transform);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={visible}
        custom={isHtml}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          {type === "success" ? (
            <CheckCircleIcon style={{ fontSize: 60 }} color="success" />
          ) : (
            <CancelIcon style={{ fontSize: 60 }} color="error" />
          )}
        </BootstrapDialogTitle>
        <DialogContent style={{ overflowY: "auto" }}>
          {isHtml ? (
            <Grid container spacing={2} margin={2}>
              {content && content.map((result) => renderComponent(result))}
            </Grid>
          ) : (
            <Typography
              gutterBottom
              style={{ textAlign: "center", color: "#919191", fontSize: 20 }}
            >
              {content}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {ordens.length > 0 ? (
            <Button
              autoFocus
              onClick={() => {
                handleClose();
                onClickDetalhe();
              }}
            >
              {"Ver detalhes"}
            </Button>
          ) : null}
          <Button
            autoFocus
            onClick={() => {
              handleClose();
              if (type === "success") {
                onClick();
              }
            }}
          >
            {btnTxt}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
