import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Moment from 'moment';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '../hooks/useQuery';
import { obterCorPorStatus } from '../utils';

import Box from '@mui/material/Box';
import CadastroItem from './CadastroItem';
import BasicSpeedDialCadastro from './SpeedDialCadastro';

const RowCadastro = ({
  fornecedor,
  index,
  onClickEdit,
  onClickDelete,
  onClickCancel,
}) => {
  const [statuses, setStatuses] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { cancelCadastroFornecedor } = useQuery();

  const onChangeAccordtion = (e, expanded) => {
    if (expanded) {
      setStatuses(fornecedor.historico);
    }
  };

  const onClickCancelCadastro = async () => {
    const confirm = window.confirm(
      `Tem certeza que deseja cancelar esse cadastro ${fornecedor.nome}?`,
    );

    if (!confirm) return;

    setIsLoading(true);
    await cancelCadastroFornecedor(fornecedor.id);
    setIsLoading(false);
    onClickCancel();
  };

  return (
    <tr key={`row${index}`} style={{}}>
      <td colSpan={6}>
        <Accordion
          style={{
            padding: 0,
            boxShadow: 'none',
            borderBottom: 'solid 1px #ddd',
          }}
          onChange={onChangeAccordtion}
        >
          <AccordionSummary
            style={{
              padding: 0,
              background: index % 2 === 0 ? 'white' : '#f0eded',
            }}
            aria-controls={'panel-content' + index}
            id={'panel-header' + index}
          >
            <table style={{ width: '100%' }}>
              <tbody>
                <tr
                  style={{ background: index % 2 === 0 ? 'white' : '#f0eded' }}
                  key={`${index}-cadastro-key`}
                >
                  <td style={{ paddingLeft: '1.2rem', width: '20%' }}>
                    {fornecedor.nome}
                  </td>
                  <td style={{ paddingLeft: '1.2rem', width: '10%' }}>
                    {fornecedor.numero_processo_fluig}
                  </td>
                  <td
                    style={{
                      paddingLeft: '1.2rem',
                      width: '15%',
                      wordBreak: 'break-all',
                    }}
                  >
                    {Moment(fornecedor.created_at).format('DD/MM/YYYY')}
                  </td>
                  <td
                    style={{
                      paddingLeft: '1.2rem',
                      width: '15%',
                      wordBreak: 'break-all',
                      color: '#DC143C',
                    }}
                  >
                    {fornecedor.data_validade &&
                    !['01/01/1900', '1900-01-01'].includes(
                      fornecedor.data_validade,
                    )
                      ? Moment(fornecedor.data_validade).format('DD/MM/YYYY')
                      : ''}
                  </td>
                  <td style={{ paddingLeft: '1.2rem', width: '10%' }}>
                    {fornecedor.documento}
                  </td>
                  <td style={{ paddingLeft: '1.2rem', width: '20%' }}>
                    <Button
                      style={{
                        borderRadius: 20,
                        width: '100%',
                        fontSize: 10,
                      }}
                      variant="outlined"
                      color={obterCorPorStatus(fornecedor.status)}
                    >
                      {fornecedor.status}
                    </Button>
                  </td>
                  <td style={{ paddingLeft: '1.2rem', width: '5%' }}>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <BasicSpeedDialCadastro
                        onClickEdit={onClickEdit}
                        onClickDelete={onClickDelete}
                        fornecedor={fornecedor}
                        onClickCancel={onClickCancelCadastro}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </AccordionSummary>
          <AccordionDetails>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <CadastroItem fornecedor={fornecedor} statuses={statuses} />
            )}
          </AccordionDetails>
        </Accordion>
      </td>
    </tr>
  );
};

export default RowCadastro;
