import React from 'react';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Modal,
  ListItemText,
} from '@mui/material';

import LoopIcon from '@mui/icons-material/Loop';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from '../hooks/useQuery';
import { useModalLoading } from '../hooks/useModalLoading';
import { useSnackbar } from '../hooks/useSnackbar';
import { handleHttpError } from '../utils';
import ReactJson from 'react-json-view';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function FailedJobs() {
  const modalLoading = useModalLoading();
  const snackbar = useSnackbar();

  const { fetchFailedJobs, retryFailedJobs } = useQuery();
  const [jobsSelected, setJobsSelected] = React.useState([]);
  const [jobsFailed, setJobsFailed] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [currentJob, setCurrentJob] = React.useState(null);
  const [search, setSearch] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const checkAll = () => {
    if (jobsSelected.length === 0) {
      setJobsSelected(jobsFailed.map((item) => item.uuid));
    } else {
      setJobsSelected([]);
    }
  };

  const handleToggle = (uuid) => () => {
    const currentIndex = jobsSelected.indexOf(uuid);
    const newChecked = [...jobsSelected];

    if (currentIndex === -1) {
      newChecked.push(uuid);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setJobsSelected(newChecked);
  };

  const fetchJobs = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchFailedJobs(search);
      setJobsFailed(
        data.map((item) => {
          item.payload_parsed = JSON.parse(item.payload);
          return item;
        }),
      );
    } catch (error) {
      snackbar.showSnackbar(handleHttpError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const retryJobs = async () => {
    const confirm = window.confirm(
      `Confirma enviar ${jobsSelected.length} seviços para reprocessamento?`,
    );

    if (!confirm) return;

    try {
      modalLoading.show('Adicionando serviços para processar na fila...');
      await retryFailedJobs({ uuid: jobsSelected });
      fetchJobs();
    } catch (error) {
      snackbar.show(handleHttpError(error));
    } finally {
      modalLoading.hide();
    }
  };

  React.useEffect(() => {
    if (!isLoading) return;

    fetchJobs();
  }, [isLoading]);

  return (
    <Box
      mt={8}
      sx={{
        width: '100%',
        height: '100%',
        minHeight: 'calc(100vh - 140px)',
      }}
      backgroundColor="#FAFCFF"
    >
      <AppBar position="static" color={'primary'} style={{ padding: 10 }}>
        <Grid container>
          <Grid
            item
            xs={12.5}
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Grid item display="flex" alignItems="center">
              <LoopIcon
                sx={{
                  fontSize: 40,
                  display: { xs: 'none', md: 'flex' },
                }}
              />
              <span style={{ color: 'white', fontSize: 20 }}>
                Reprocessar jobs
              </span>
            </Grid>

            <span style={{ color: 'white', fontSize: 16 }}></span>
          </Grid>
        </Grid>
      </AppBar>

      <Grid item sm={9} paddingLeft="1rem" paddingRight="1rem" marginTop="2rem">
        <div style={{ marginBottom: '10px' }}>
          <Button
            variant="outlined"
            style={{ marginRight: '4px' }}
            onClick={() => setIsLoading(true)}
          >
            Atualizar
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: '4px' }}
            onClick={checkAll}
          >
            {jobsSelected.length === 0
              ? 'Selecionar todos'
              : 'Desselecionar todos'}
          </Button>
          <Button
            disabled={jobsSelected.length === 0}
            variant="contained"
            onClick={retryJobs}
          >
            Enviar
          </Button>
        </div>

        <OutlinedInput
          fullWidth
          placeholder="Digite para pesquisar por ID da nota ou login"
          style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
          onChange={({ target }) => {
            setSearch(target.value);
          }}
          value={search}
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" onClick={fetchJobs}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />

        <Card variant="outlined">
          {isLoading && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '20px',
              }}
            >
              <CircularProgress size={20} />
            </div>
          )}

          {!isLoading && (
            <CardContent>
              {jobsFailed.map((item) => {
                const labelId = `checkbox-list-label-${item.uuid}`;

                return (
                  <ListItem
                    key={item.uuid}
                    disablePadding
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="morehorizon"
                        onClick={() => {
                          setCurrentJob(item);
                          handleOpen();
                        }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(item.uuid)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={jobsSelected.indexOf(item.uuid) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${item.payload_parsed.displayName}`}
                        secondary={`${item.uuid} - ${item.failed_at}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </CardContent>
          )}
        </Card>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {currentJob && (
            <>
              <p>Payload:</p>

              <ReactJson
                src={currentJob && currentJob.payload_parsed}
                style={{ height: 300, overflowY: 'scroll' }}
              />

              <hr />
              <div style={{ height: '300px', overflow: 'scroll' }}>
                <p>Exception:</p>

                <div style={{ height: 300, overflowY: 'scroll' }}>
                  {currentJob.exception}
                </div>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default FailedJobs;
