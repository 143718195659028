import CryptoJS from 'crypto-js';
import Utf8 from 'crypto-js/enc-utf8';

export const EM_ANALISE_GESTOR = 'EM ANÁLISE GESTOR';
export const PAGAMENTO_NAO_REALIZADO = 'PAGAMENTO NÃO REALIZADO';
export const EM_ANALISE_FINANCEIRO = 'EM ANÁLISE FINANCEIRO';
export const PENDENCIA_GESTOR = 'PENDÊNCIA GESTOR';
export const AGUARDANDO_PAGAMENTO = 'AGUARDANDO PAGAMENTO';
export const PENDENCIA_FORNECEDOR = 'PENDÊNCIA DO FORNECEDOR';
export const ENVIADO_AO_FLUIG = 'ENVIADO AO FLUIG';
export const EM_ANALISE = 'EM ANÁLISE';
export const AGUARDANDO_APROVACAO = 'AGUARDANDO APROVAÇÃO';
export const PAGO = 'PAGO';
export const PENDENTE = 'PENDENTE';
export const RASCUNHO = 'RASCUNHO';
export const CANCELADO = 'CANCELADO';
export const ERRO_NO_PAGAMENTO = 'ERRO NO PAGAMENTO';

export const statuses = [
  EM_ANALISE_GESTOR,
  // PAGAMENTO_NAO_REALIZADO,
  EM_ANALISE_FINANCEIRO,
  PENDENCIA_GESTOR,
  AGUARDANDO_PAGAMENTO,
  PENDENCIA_FORNECEDOR,
  // ENVIADO_AO_FLUIG,
  EM_ANALISE,
  // AGUARDANDO_APROVACAO,
  PAGO,
  // PENDENTE,
  RASCUNHO,
  CANCELADO,
  ERRO_NO_PAGAMENTO,
];

const LEGENDA_STATUS_COLOR = {
  [EM_ANALISE_GESTOR]: 'info',
  // [PAGAMENTO_NAO_REALIZADO]: "warning",
  [EM_ANALISE_FINANCEIRO]: 'warning',
  [PENDENCIA_GESTOR]: 'error',
  ['PENDÊNCIA']: 'error',
  [ERRO_NO_PAGAMENTO]: 'error',
  [AGUARDANDO_PAGAMENTO]: 'primary',
  [PENDENCIA_FORNECEDOR]: 'error',
  'PENDENCIA DO FORNECEDOR': 'error',
  [CANCELADO]: 'error',
  // [ENVIADO_AO_FLUIG]: "error",
  [EM_ANALISE]: 'warning',
  // [AGUARDANDO_APROVACAO]: "info",
  [PAGO]: 'success',
  ['ATIVO']: 'success',
  [PENDENTE]: 'warning',
  [RASCUNHO]: 'warning',
};

// String
export const obterCorPorStatus = (status) => {
  const newStatus = status.toUpperCase();
  if (LEGENDA_STATUS_COLOR[newStatus]) return LEGENDA_STATUS_COLOR[newStatus];
  return 'primary';
};

// default 10mb
export const isFileBig = (fileSize, maxSize = 10000) => {
  return Math.round(fileSize / 1024) > maxSize;
};

export const isCnpj = (cnpj) => {
  return /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/.test(
    cnpj,
  );
};

export const decodeUnicode = (str) => {
  return decodeURIComponent(JSON.parse('"' + str.replace(/\"/g, '\\"') + '"'));
};

export const serializeQueryString = (query, initialParams = '?') => {
  let queryString = '';
  for (let key in query) {
    if (queryString === '') {
      queryString = `${initialParams}${key}=${query[key]}`;
    } else {
      queryString += `&${key}=${query[key]}`;
    }
  }
  return queryString;
};

export const cryptedParam = (text, salt = 'salt') => {
  const textToChars = (text) =>
    String(text)
      .split('')
      .map((c) => c.charCodeAt(0));
  const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return String(text)
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
};

export const decryptedParam = (encoded, salt = 'salt') => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
};

export const tryParse = (text) => {
  try {
    return JSON.parse(text);
  } catch (error) {
    return '';
  }
};

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    String(data),
    'cpV2nJxoG5CubsHgadKG',
  ).toString();
  const wordArray = CryptoJS.enc.Base64.parse(encrypted);
  return CryptoJS.enc.Hex.stringify(wordArray);
};

export const decryptData = (data) => {
  const wordArray = CryptoJS.enc.Hex.parse(data);
  const toDecrypt = CryptoJS.enc.Base64.stringify(wordArray);
  return CryptoJS.AES.decrypt(toDecrypt, 'cpV2nJxoG5CubsHgadKG').toString(Utf8);
};

export const maskPhone = (phone) => {
  phone = phone.replace(/\D/g, '');
  phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2');
  phone = phone.replace(/(\d)(\d{4})$/, '$1-$2');
  return phone;
};

export const maskCep = (cep) => {
  return cep.replace(/(\d{5})(\d{3})/g, '$1-$2');
};

export const maskCpf = (cpf) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

export const handleHttpError = (error) => {
  let msg = '';

  if (!error.response) {
    msg = 'Houve um erro inesperado. Tente novamente mais tarde.';
  } else {
    const status = error.response.status;
    switch (status) {
      case 400:
        msg = error.response.data.message;
        break;
      case 404:
        msg = error.response.data.message
          ? error.response.data.message
          : 'Houve um erro inesperado. Recurso não encontrado';
        break;
      case 422:
        Object.entries(error.response.data.errors).forEach(([key, value]) => {
          msg = value[0];
        });
        break;
      case 401:
        msg = error.response.data.message
          ? error.response.data.message
          : 'Você não tem permissão para acessar esse recurso. Efetue o login novamete';
        break;
      case 500:
        console.log(error.response.data);
        msg = error.response.data.message
          ? error.response.data.message
          : 'Houve um problema ao comunicar-se com o servidor. Tente novamente mais tarde.';
        break;
      default:
        msg = 'Houve um erro inesperado. Tente novamente mais tarde.';
    }
  }

  return msg;
};
