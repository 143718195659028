import { createContext, useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { CircularProgress } from "@mui/material";
const ModalLoadingContext = createContext();

const BootstrapDialog = styled(Dialog)(({ theme, custom }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: custom ? "auto" : 400,
    border: "none",
    overflow: custom ? "hidden" : "auto",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const ModalLoading = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");

  const show = (msg = "") => {
    setVisible(true);
    setMessage(msg);
  };

  const hide = () => {
    setVisible(false);
    setMessage("");
  };

  return (
    <ModalLoadingContext.Provider
      value={{
        show,
        hide,
      }}
    >
      {children}
      <BootstrapDialog open={visible}>
        <div
          style={{
            paddingTop: "4rem",
            marginBottom: "4rem",
            paddingLeft: "6rem",
            paddingRight: "6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color={"inherit"} size={25} />
          <p>{message}</p>
        </div>
      </BootstrapDialog>
    </ModalLoadingContext.Provider>
  );
};

export const useModalLoading = () => {
  const context = useContext(ModalLoadingContext);

  if (!context) {
    throw new Error("useModalLoading não possuí context");
  }

  return context;
};
