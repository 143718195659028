import Snackbar from "@mui/material/Snackbar";
import { createContext, useContext, useMemo, useState } from "react";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null);

  const showSnackbar = (msg) => {
    setMessage(msg);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
      }}
    >
      {children}
      <Snackbar
        open={show}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error("useSnackbar não possuí context");
  }

  return context;
};
